import { Box, Typography } from "@mui/material";
import React from "react";

export default function Header() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          maxWidth: { xs: "120px", sm: "200px" },
          overflow: "hidden",
        }}
      >
        <img
          src="/images/profile.png"
          alt="Vijay Vijayaram"
          style={{
            width: "100%",
            borderRadius: "15px",
          }}
        />
      </Box>
      <Typography variant="h5">Vijay Vijayaram</Typography>
      <Typography variant="body2" align="center">
        <strong>Engineering Leadership | Architecture | Development</strong>
      </Typography>
    </Box>
  );
}
