import { Box, Chip } from "@mui/material";
import React from "react";

const experiences = [
  "Engineering Leadership",
  "Architecture & Design",
  "Hands-on Development",
  "Requirement Analysis",
  "Enterprise Integrations",
];

export default function Experience() {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: 1,
        mt: 2,
        mb: 2,
      }}
    >
      {experiences.map((exp) => (
        <Chip key={exp} label={exp} color="primary" size="small" />
      ))}
    </Box>
  );
}
