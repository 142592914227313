import { Box } from "@mui/material";
import React from "react";
import Summary from "./Summary.component";
import Experience from "./Experience.component";
import Links from "./Links.component";
import Skills from "./Skills.component";
import Header from "./Header.component";

export default function Home() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: "100%",
        pb: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          mt: { xs: "5%", sm: "10%" },
          maxWidth: "80vw",
        }}
      >
        <Header />
        <Links />
        <Summary />
        <Experience />
        <Skills />
      </Box>
    </Box>
  );
}
