import { Box, Typography } from "@mui/material";
import React from "react";

export default function Summary() {
  return (
    <Box sx={{ textAlign: "center", mt: 2 }}>
      <Typography variant="body1" marginBottom={2}>
        Currently as <strong>Head of Engineering & Architecture</strong> at{" "}
        <a href="https://www.mountsinai.org">Mount Sinai Health System</a>.
        Previously, engineering leadership role at Bottomline Technologies,
        Andera and Trade Ship Inc.
      </Typography>
    </Box>
  );
}
