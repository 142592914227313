import { Box, Rating, Typography } from "@mui/material";
import React from "react";

const skills = [
  {
    skill: "Java",
    level: 5,
  },
  {
    skill: "Goland",
    level: 5,
  },
  {
    skill: "Javascript",
    level: 5,
  },
  {
    skill: "ReactJS",
    level: 5,
  },
  {
    skill: "SQL",
    level: 5,
  },
  {
    skill: "Python",
    level: 4,
  },
  {
    skill: "Scala",
    level: 3,
  },
  {
    skill: "Rust",
    level: 2,
  },
  {
    skill: "Java EE/Jakarta",
    level: 5,
  },
  {
    skill: "Postgres",
    level: 5,
  },
  {
    skill: "MySQL",
    level: 4,
  },
  {
    skill: "Oracle DB",
    level: 4,
  },
  {
    skill: "MongoDB",
    level: 4,
  },
  {
    skill: "Rust",
    level: 2,
  },
  {
    skill: "AWS",
    level: 5,
  },
  {
    skill: "Azure",
    level: 3,
  },
  {
    skill: "Generative AI",
    level: 3,
  },
  {
    skill: "HL7",
    level: 4,
  },
  {
    skill: "FHIR",
    level: 4,
  },
  {
    skill: "EDI",
    level: 3,
  },
  {
    skill: "Unit/e2e Tests",
    level: 5,
  },
  {
    skill: "CI/CD",
    level: 5,
  },
  {
    skill: "Security",
    level: 4,
  },
  {
    skill: "Monitoring",
    level: 4,
  },
  {
    skill: "Agile Management",
    level: 5,
  },
  {
    skill: "Scrum",
    level: 5,
  },
];

export default function Skills() {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: 1,
        mt: 2,
      }}
    >
      {skills.map((skill) => (
        <Box
          key={skill.skill}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid silver",
            borderRadius: "10px",
            p: "0 8px",
            background: "#fff",
          }}
        >
          <Typography variant="caption" marginRight="2px">
            {skill.skill}
          </Typography>
          <Rating
            value={skill.level}
            size="small"
            readOnly={true}
            sx={{ fontSize: "12px" }}
          />
        </Box>
      ))}
    </Box>
  );
}
