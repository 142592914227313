import { Box, Link } from "@mui/material";
import React from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import EmailIcon from "@mui/icons-material/Email";

export default function Links() {
  return (
    <Box sx={{ display: "flex", gap: 1, mt: 2, mb: 2 }}>
      <Link href="https://www.linkedin.com/in/vijayvi" target="_blank">
        <LinkedInIcon sx={{ color: "0b66c2", fontSize: "24px" }} />
      </Link>
      <Link href="https://www.github.com/kumarvv" target="_blank">
        <GitHubIcon sx={{ color: "#000", fontSize: "24px" }} />
      </Link>
      {false && (
        <Link href="https://leetcode.com/kumarvv/" target="_blank">
          <GitHubIcon sx={{ color: "#000", fontSize: "24px" }} />
        </Link>
      )}
      <Link href="mailto://vijay@vijayvi.com" target="_blank">
        <EmailIcon sx={{ color: "orange", fontSize: "24px" }} />
      </Link>
    </Box>
  );
}
